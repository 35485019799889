import { setSessionStorage, getSessionStorage } from '@shein/common-function'

const cacheKey = 'activity_popover_show_histories'

export const useLureBubbleHistory = () => {
  const getLureBubbleHistory = () => {
    if (typeof window === 'undefined') return []
    return getSessionStorage(cacheKey) || []
  }

  const setLureBubbleHistory = (page, type) => {
    if (typeof window === 'undefined') return
    if (!type) return
    let data = getLureBubbleHistory()
    data = data.filter(f => f.type !== type)
    data.push({
      page,
      type,
    })
    setSessionStorage({ key: cacheKey, value: data })
  }

  if (typeof window !== 'undefined') {
    window.getLureBubbleHistory = getLureBubbleHistory
  }

  return {
    getLureBubbleHistory,
    setLureBubbleHistory,
  }
}
