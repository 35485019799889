/**
 * 超限
 */
export default () => ({
  overLimitState: {
    /**
     * 闪购超限商品id
     */
    flashOverLimitId: new Set(),
    /**
     * 库存超限商品id
     */
    stockOverLimitId: new Set()
  }
})
