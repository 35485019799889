import CartGuide from '../CartGuide.js'

class BottomPromoGuide extends CartGuide {
  constructor() {
    super()
  }
}

const _bottomPromoGuide = typeof window !== 'undefined' ? window.__cartBottomPromoGuide__ || (window.__cartBottomPromoGuide__ = new BottomPromoGuide()) : {}

export default _bottomPromoGuide
