import CartGuide from '../CartGuide.js'

class NewNavBarGuide extends CartGuide {
  constructor() {
    super()
  }
}

const _newNavBarGuide = typeof window !== 'undefined' ? window.__cartNewNavBarGuide__ || (window.__cartNewNavBarGuide__ = new NewNavBarGuide()) : {}

export default _newNavBarGuide
