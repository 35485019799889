import { SToast } from '@shein-aidc/sui-toast/mobile'
import cartGuideManager from '../components/cartGuideManager/index.js'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'

export const handlerCouponSaveToast = async (tip, type) => {
  // 新人指引未完成，不显示toast
  const res = await cartGuideManager.isCompletedForAll()

  if (res && tip) {
    SToast(tip)
  }

  exposeScenesAbtAggregator.addEvent({
    daId: '1-8-7-60',
    extraData: {
      scenes: 'coupon_add_toast',
      type: type,
    }
  })
}
