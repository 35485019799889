const cacheKey = 'mini_cart_lure_point_showed'

export const useLurePointCache = () => {
  const getLurePointTypes = () => {
    const data = sessionStorage.getItem(cacheKey)
    return data ? data.split(',') : []
  }
  const setLurePointTypes = (type) => {
    if (!type) return
    const data = getLurePointTypes()
    if (!data.includes(type)) data.push(type)
    sessionStorage.setItem(cacheKey, data.join())
  }
  return {
    getLurePointTypes,
    setLurePointTypes,
  }
}
