import { setSessionStorage, getSessionStorage } from '@shein/common-function'

const cacheKey = 'last_lure_show_histories'

export const useLastLureBubble = () => {
  const getLastLureBubble = (clear = true) => {
    if (typeof window === 'undefined') return null
    const res = getSessionStorage(cacheKey)
    if (clear) setLastLureBubble() // clear
    return res
  }

  const setLastLureBubble = (type) => {
    if (typeof window === 'undefined') return
    setSessionStorage({ key: cacheKey, value: type ? { type } : null })
  }

  return {
    getLastLureBubble,
    setLastLureBubble,
  }
}
