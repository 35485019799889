// 购物车正向交互弹出引导结算气泡
export default () => ({
  cartItemGuideCheckoutState: {
    // 是否展示气泡
    showPopover: false,
    // 当前操作的商品行id
    currentActiveItemId: '',
    // 当前展示气泡的商品行id
    activePopoverCartId: '',
    // 是否到达展示时机：关闭弹窗、从商详返回购物车
    isShowPopoverTime: false,
    // 气泡定时器
    popoverTimer: null,
    // 缓存（产品要求页面刷新算一次生命周期，刷新时需初始化，适合state记录）
    cache: {
      TRIGGERED_COUNT: 0, // 已触发次数
      LAST_TRIGGERED_TIMESTAMP: '', // 上次触发的时间戳
      TRIGGERED_ITEM_IDS: [], // 已触发的商品行id
    }
  }
})
