const getters = {
  isFilterWishlist(state) {
    return state.filterState?.selectFilterLabel?.filterTagId === 'wishlist'
  },
  hasWishListTab(state) {
    return state.filterState?.allFilterLabels?.find?.(item => item.filterTagId === 'wishlist') ? 1 : 0
  },
}

export default getters
