import { detectUserInteraction, isMHoursNCountNoClickCheckout } from './incentiveTools.js'
import CartBubble from './CartBubble.js'
import CartRealTimeBubble from './CartRealTimeBubble.js'

class CartCheckoutIncentive {
  constructor() {
    this.cartBubble = new CartBubble()
    this.cartRealTimeBubble = new CartRealTimeBubble(this)
    this.status = 'notStarted'
    this.hooks = {}

    this.lastCartLure = null

    this.showTimer = null
    this.hideTimer = null

    this.lockShow = false
    this.lockShowTimer = null
  }

  updateCartLureList(cartLureInfo) {
    this.cartBubble.updateBubbleList(cartLureInfo)
    this.cartRealTimeBubble.updateBubbleList(cartLureInfo)
  }

  initialize(cartLureInfo, config) {
    if (typeof window === 'undefined') return
    if (!(this.status === 'notStarted' || this.status === 'stopped')) return
    this.cartBubble.initialize(cartLureInfo, config)
    this.cartRealTimeBubble.initialize(cartLureInfo)

    this.lastCartLure = null

    this.status = 'notStarted'

    let hasLimitCartLure = !!this.cartBubble.getLimitBubble()
    if (hasLimitCartLure) {
      this.start()
    } else {
      const opts = {
        noInteractionTimeout: this.cartBubble.config.noActDuration || 3, // 单位为秒
        scrollThreshold: 2, // 屏幕高度的倍数
        onNoInteraction: () => {
          console.log('用户在首次进入3秒内没有交互。')
          this.start()
        },
        onScrollThresholdReached: () => {
          console.log('用户滚动超过了两屏。')
          this.start()
        },
      }
      if (['A', 'B'].includes(this.cartBubble?.popTiming)) {
        const isOverUnCheckoutCount = isMHoursNCountNoClickCheckout(this.cartBubble?.config?.maxUnsettledHours, this.cartBubble?.config?.maxUnsettledCount)
        if (isOverUnCheckoutCount) { // 用户M小时N次进入购物车未结算
          this.start()
          return
        }
        // 用户进入购物车停留N秒。
        opts.enterCartPageTimeout = this.cartBubble?.config?.cartStaySecondsThreshold || 3 // 进入购物车时间
        opts.onEnterCartPage = () => {
          this.start()
        }
        detectUserInteraction(opts)
      } else {
        detectUserInteraction(opts)
      }

    }
  }

  async triggerShow() {
    clearTimeout(this.showTimer)
    this.showTimer = null

    const isStop = this.callIsStop()
    if (isStop) return

    let isRealTime = true
    let cartLure = this.cartRealTimeBubble.getNextBubble()
    if (!cartLure) {
      cartLure = this.cartBubble.getNextBubble()
      isRealTime = false
    }
    if (!cartLure) {
      this.stop()
      return
    }

    const res = await this.hooks.show?.({
      cartLure,
    })
    if (res) {
      this.lastCartLure = cartLure
      if (isRealTime) {
        // 实时类气泡
        this.cartRealTimeBubble.addIndex()
        this.cartRealTimeBubble.setDisplayedBubbleListMap(cartLure)
      } else {
        // 非实时类气泡
        this.cartBubble.addIndex()
        this.cartBubble.setDisplayedBubbleListMap(cartLure)
      }
      
      if (this.cartBubble.popTiming != 'B') {
        this.setHideTimer(isRealTime)
      } else {
        this.setLockShowTimer()
      }
    } else {
      this.setShowTimer()
    }
  }

  triggerShowByRealTime() {
    if (this.status === 'running') {
      if (this.hideTimer) return // 有其他气泡在展示中，下一次再展示
      this.triggerShow()
      return
    }
    if (this.status === 'notStarted') {
      this.start()
      return
    }
    if (this.status === 'stopped') {
      this.status = 'notStarted'
      this.start()
      return
    }
  }

  async triggerHide(param = { forceHide: true }) {
    // 如果弹窗被锁定展示，则返回（原点击关闭按钮、打开抽屉等逻辑保持强制关闭，不受锁定影响）
    if (!param.forceHide && this.lockShow) {
      return
    }

    clearTimeout(this.hideTimer)
    this.hideTimer = null

    await this.hooks.hide?.()

    this.setShowTimer()
  }

  setShowTimer() {
    clearTimeout(this.showTimer)
    this.showTimer = null

    this.callIsStop()

    if (this.status === 'stopped' || this.status === 'paused') return

    this.showTimer = setTimeout(() => {
      clearTimeout(this.showTimer)
      this.showTimer = null

      if (this.status === 'stopped' || this.status === 'paused') return
      this.triggerShow()
    }, this.getIntervals() * 1000)
  }

  setHideTimer(isRealTime) {
    clearTimeout(this.hideTimer)
    this.hideTimer = null

    this.callIsStop()

    let disappearDuration = this.cartBubble.config.disappearDuration
    // 如果当前展示的是实时类气泡，则使用实时类气泡 disappearDuration
    if (isRealTime) disappearDuration = this.cartRealTimeBubble.config.disappearDuration
    this.hideTimer = setTimeout(() => {
      clearTimeout(this.hideTimer)
      this.hideTimer = null

      this.triggerHide()
    }, disappearDuration * 1000)
  }

  setLockShowTimer() {
    clearTimeout(this.lockShowTimer)
    this.lockShowTimer = null
    const lockDuration = (this.cartBubble.config.maxBubbleDurationSeconds * 1000) || 3000
    
    this.lockShow = true
    this.lockShowTimer = setTimeout(() => {
      this.lockShow = false
      clearTimeout(this.lockShowTimer)
    }, lockDuration)
  }

  getIntervals() {
    let intervals = this.cartBubble.getIntervals()
    // 如果还有实时类气泡要展示，则使用实时类气泡 intervals
    if (!this.cartRealTimeBubble.getIsMaxDisplayNum() && this.cartRealTimeBubble.getHasNextBubble()) intervals = this.cartRealTimeBubble.config.intervals
    return intervals
  }

  callIsStop() {
    if (this.status === 'stopped') return true
    if (this.cartRealTimeBubble.getIsMaxDisplayNum() && this.cartBubble.getIsMaxDisplayNum()) {
      this.stop()
      return true
    }
    return false
  }

  // 开始
  start() {
    if (this.status !== 'notStarted') return
    this.status = 'running'

    this.triggerShow()
  }

  // 暂停
  async pause() {
    if (this.status == 'stopped') this.hooks.hide?.()
    if (this.status !== 'running') return
    this.status = 'paused'

    await this.triggerHide()
  }

  // 重启
  async restart() {
    if (this.status !== 'paused') return
    this.status = 'running'

    await this.triggerHide()
  }

  // 结束
  stop({ hide = false } = {}) {
    if (hide) this.hooks.hide?.()

    this.status = 'stopped'

    // 清除定时器
    clearTimeout(this.showTimer)
    this.showTimer = null
    clearTimeout(this.hideTimer)
    this.hideTimer = null
    clearTimeout(this.lockShowTimer)
    this.lockShowTimer = null
  }

  setHooks(hooks) {
    this.hooks = {
      show: (...args) => {
        return hooks.show?.(...args)
      },
      hide: (...args) => {
        return hooks.hide?.(...args)
      },
    }
  }
}

// 单例
const _cartCheckoutIncentive = typeof window !== 'undefined' ? window.__cartCheckoutIncentive__ || (window.__cartCheckoutIncentive__ = new CartCheckoutIncentive()) : {
  updateCartLureList: () => {},
  pause: () => {},
  restart: () => {},
}

export default _cartCheckoutIncentive
