import CartGuide from '../CartGuide.js'

class CouponFilterGuide extends CartGuide {
  constructor() {
    super()
  }
}

const _couponFilterGuide = typeof window !== 'undefined' ? window.__cartCouponFilterGuide__ || (window.__cartCouponFilterGuide__ = new CouponFilterGuide()) : {}

export default _couponFilterGuide
