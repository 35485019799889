const state = {
  moduleName: '', //主车cart_v2,普通非标车mini_cart
  hideComponents: [], // 隐藏不展示的组件名称
  languageAndAbtIsLoaded: false, // mini cart语言包和abt是否加载完成
  ssrPageType: '',
  appCccBannerConfigInfo: null,
  isSSR: false,
  locals: null,
  loginStatus: false,
  cartInfo: {},
  originCartInfo: {},
  language: {},
  cartAbtInfo: {
    PayinCart: {},
    OpenCartCouponHelpernew: {},
    CartCouponHelpernew: {},
    TradeQuickship: {},
    Discountlistshowlimitexceededtip: {},
    OrderPageCouponTest: {},
    UnderPrice: {},
    LowpriceUser: {},
    Cartcouponadd: {},
    recSwitch: {},
    addJson: {},
    addnewhot: {},
    addnewhotJson: {},
    cartLabels: {},
    EstimatedDetailSwitch: {},
    CouponAddToCartToast: {},
    CartNotCheckedEstimate: {},
    CartrowEstimatedCountdown: {},
    DiscountInfoSwitch: {},
    cartSelect: {},
    CartAddress: {},
    CartUnpaidOrder: {},
    CartShippingTopSwitch: {},
    evoluSHEINShow: {},
    CancelledorderTip: {},
    cartDiscountStyle: {},
    storeiconchange: {},
    Cartrefund: {},
    cartPromotions: {},
    detailPriceCountDown: {},
    LowestPriceDays: {},
    paypalCartSwitch: {},
    CartaddGoodsdetailSwitch: {},
    cartRightsFuns: {},
    CartNewCouponlist: {},
    cartPriceStyle: {},
    cartRecommendGuide: {},
    PaymentSafetyExperiment: {},
    PaymentSecurity: {},
    cartShowBrand: {},
    cartStoreFreeGift: {},
    outofstocktips: {},
    promotionCouponMix: {},
    controlActivityOver: {},
    CartGetCoupons: {},
    CartRecommendRanklist: {},
    cartBottomStyle: {},
    CartBtnPopTiming: {},
  },
  carts: [],
  cartIds: [],
  cartItemMap: {}, // 包含所有商品信息（当前展示的，和被筛选的）
  mallShippingInfo: {},
  auto_use_coupon: 0,
  renderToCartId: '',
  batchActive: false,  // 是否批量编辑
  batchEditItemIds: [], // 批量编辑商品id
  soldoutItems: [], // 所有告罄商品
  soldoutIds: [], // 所有告罄商品id
  mallCartList: [], // 商城商品列表
  isPlatForm: false, // 是否多mall
  cartNoticeMessage: '', // 购物车顶部提示
  titleNavStyleFromCcc: null, // 从ccc获取的titleNav样式
  topRightsFuns: {}, // 顶部权益
  topBannerInfo: {}, // 顶部banner
  savedPrice: {}, // 节省金额
  openedDeleteItemId: '', // 已打开删除的商品id
  scrolledItemId: '', // 侧滑打开的商品行id
  isUpdateHelperOnly: false,
  dialogComponent: {
    other: null
  }, // 按需加载弹窗组件
  drawerCouponHelper: false, // 打开券助手列表
  showAddressSelectedHeight: false,
  unpaidOrderInfo: {}, // 未支付订单信息/取消订单信息
  loadingShow: false, // loading
  modalOptions: {
    show: false,
    okCallback: () => {
      this.show = false
    },
    cancelCallback: () => {
      this.show = false
    },
    oktext: '',
    canceltext: '',
    text: '',
    component: null,
    okDaId: '',
    cancelDaId: ''
  },
  showCanceledOrderDraw: false, // 是否展示取消订单弹窗
  showDiscountDetails: false, // 是否展示优惠详情
  priceMode: 'normal', // 价格模式 normal: 普通价格模式 suggestV1: 法国合规价模式 suggestV1Special: 波兰合规价模式 suggestV2: 德国合规价模式
  asyncComponentLoadedStatus: {
    // addForCouponIsLoaded: false,
    // cartShareDrawIsLoaded: false,
    cartFullGiftsDrawIsLoaded: false,
    cartAddBuyDrawIsLoaded: false,
    cartNewGoodsRecommendDrawIsLoaded: false,
    cartDiscountDetailDrawIsLoaded: false,
    cartBundlingDrawerIsLoaded: false,
    cartAllDiscountDetailDrawIsLoaded: false,
    cartEmailConfirmDrawIsLoaded: false,
    cartAccountRegisterDrawIsLoaded: false,
    cartRegisterFailedDrawIsLoaded: false,
    canceledOrderProductListIsLoaded: false,
    cartFloorDrawerIsLoaded: false,
    cartLowPriceUserDrawIsLoaded: false,
    cartPromotionInterceptionDrawIsLoaded: false,
    cartPromotionInterceptionV2DrawIsLoaded: false,
    soldOutDialogIsLoaded: false,
    negativeInformationDrawIsLoaded: false,
    priceChangeDetailsDrawIsLoaded: false,
  },
  newGoodsRecommendParams: {
    show: false,
    type: '', // popover | tip | toast
    selectedCartItemId: '',
  },
  discountDetailDrawerParams: {
    show: false,
    data: null,
  }, // 优惠详情抽屉参数
  allDiscountDetailDrawerParams: {
    show: false,
    anchorType: '', // 需要锚定的类型,promotion,gift,coupon
    anchorId: '', // 需要锚定的id,promotionId/couponCode
  },
  defaultAddress: {}, // 默认地址信息
  isAddressLocalUpdate: false,
  lastCheckStockTime: 0, // 最后一次检查库存时间
  outStockCarts: [], // 缺货商品
  soldOutDialogShow: false, // 缺货商品弹窗
  showLowPriceUser: false, // 是否展示低客单
  showPromotionInterception: false, // 是否展示促销拦截
  showPromotionInterceptionV2: false, // 是否展示促销拦截V2
  negativeInformationDraw: {
    show: false,
    mallCode: ''
  }, // 是否展示负向信息
  batchDeleteStayPopUp: {}, // 批量删除弹窗数据
  appendageDrawer: {
    promotionId: '', // 附属品选择弹窗的促销id
    fromType: '', // addItem:凑单页手动唤起附属品弹窗，addItem-auto:凑单页自动唤起附属品弹窗,pick:直接唤起附属品选择弹窗(默认)
    fullGiftShow: false, // 新满赠
    addBuyShow: false, // 新加价购
    buyGiftShow: false, // 新买赠
    clubGiftShow: false, // 付费会员免费礼物权益
  },
  cartLeave: false, // 是否离开购物车
  couponInfo: {}, // 券信息
  isShowCartFloorDrawer: false, // 是否展示购物车楼层弹窗
  showCartBundlingDrawer: false, // 整车优惠明细凑单弹窗
  showCartBundingId: '', // 整车优惠明细凑单弹窗id/券码
  // 购物车使用bff接口开关
  useBffApi: {
    cart_v4: false,
  },
  mallCartBiData: {}, // 商城商品埋点数据
  popupCartLure: {}, //  购物车楼层弹窗数据
  localChecked: {}, // 购物车商品本地选中状态
  monitorUserInteraction: null,
  showRecommendGuideA: false,
  recommendGoods: [], // 推荐商品信息，在引导至推荐a方案的气泡中显示
  hasPlatformInMallTags: null, // 平台标签
  drawerGetCoupon: false, // 打开领券弹窗
  mallInfoForGetCoupon: {}, // 传给领券列表接口的店铺数据
  customDrawerKeys: [], // 自定义弹窗key
  mallShippingAnalysisParam: {},
  showPriceChangeDetailsDrawer: false, // 是否展示价格变动详情抽屉
  priceChangeDetailsData: {}, // 价格变动详情数据
  assistPolicyData: null, // 辅助决策信息
  refreshRecommendFlag: false, // 刷新推荐数据标识
  notShowCartFloorDrawer: false, // 单利诱商品不展示购物车楼层弹窗
  cartConfigs: {}, // 购物车配置信息
  replayTrigger: 0, // low stock icon animation replay trigger
  cartLowStockExposeFlagIds: [], // 低库存曝光商品id
  cartLowStockHasClose: false, // 低库存气泡是否关闭
  popoverManagerModalCount: 0, // 气泡管理模态框数量
  isRefreshHanlder: false, // 是否正在刷新购物车
  cartSameLurePopupInfo: null, // 购物车相同商品诱导弹窗信息
  cartAddItemPopupInfoList: [], // 购物车加购弹窗信息列表
  buyBoxDrawerInstance: null, // boyBox 同款低价推荐弹窗实例
  buyBoxPriceTop: {}, // boyBox 同款低价推荐弹窗插槽渲染信息
}

export default () => ({
  ...state
})
