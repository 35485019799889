import commonState from './commonState'
import filterState from './filterState'
import carouselState from './carouselState'
import promotionState from './promotionState'
import orderState from './orderState'
import checkoutState from './checkoutState'
import paypalState from './paypalState'
import overLimitState from './overLimitState'
import priceChangeState from './priceChangeState'
import recommendState from './recommendState'
import cartItemGuideAddOnState from './cartItemGuideAddOnState'
import cartItemGuideCheckoutState from './cartItemGuideCheckoutState'

// mini cart 使用
import miniCartState from './miniCartState'

export const stateFactory = () => ({
  ...commonState(),
  ...filterState(),
  ...carouselState(),
  ...promotionState(),
  ...orderState(),
  ...checkoutState(),
  ...paypalState(),
  ...overLimitState(),
  ...priceChangeState(),
  ...recommendState(),
  ...cartItemGuideAddOnState(),
  ...cartItemGuideCheckoutState(),

  // mini cart 使用
  ...miniCartState(),
})
