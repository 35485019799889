import schttp from 'public/src/services/schttp'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
const { CHECKOUT_PRERENDER_CONFIG } = gbCommonInfo
const prerenderCheckout = () => ({
  active: typeof window !== 'undefined',
  leave: 0,
  stop: false,
  debounceTime: +CHECKOUT_PRERENDER_CONFIG?.debounceTime || 1500,
  liteInfoTime: +CHECKOUT_PRERENDER_CONFIG?.liteInfoTime || 5 * 60 * 1000,
  refreshTime: +CHECKOUT_PRERENDER_CONFIG?.refreshTime || 20 * 1000,
  refreshTimer: null,
  cache: null,
  abortController: null,
  protectAbortController: null,
  liteInfo: null,
  paymentCode: '',
  prefetchDone: false,
  init() {
    if (typeof window === 'undefined') return

    this._getLiteInfo()
    setInterval(() => this._getLiteInfo(), this.liteInfoTime)

    if (this.active) {
      document.onvisibilitychange = () => this.onvisibilitychange()
    }

    window.TPM?.run?.({
      marketing: 'InitChannelSdk',
      method: '_initC2pSdk'
    })
  },
  async _getLiteInfo() {
    try {
      this.liteInfo = await schttp({
        url: `/api/checkout/getLiteInfo`,
        schttp: { apiLogSkip: true },
      })
    } catch (error) {
      this.liteInfo = null
    }
    return this.liteInfo
  },
  async _prefetchOnce() {
    if (this.prefetchDone) return
    this.prefetchDone = true
    requestIdleCallback(() => prefetchResource.prefetchJs({
      prefetchList: [
        { chunkName: 'checkout_app', relType: 'preload' },
      ]
    }))
  },
  async _request(protect) {
    this.abortController?.abort()
    this.abortController?.cancel()
    this.abortController = null
    const abortController = new SchttpAbortCon()

    if (!protect) {
      this.abortController = abortController
      await Promise.race([
        new Promise(res => setTimeout(res, this.debounceTime)),
        new Promise((res, rej) => {
          this.abortController.resolve = res
          this.abortController.cancel = () => rej(new Error('canceled'))
        // eslint-disable-next-line no-console
        }).catch((e) => console.log('cancel:' + e))
      ])
    }
    this.protectAbortController = abortController
    let fetchList = [schttp({
      url: `/api/checkout/get?prefetch=1&lite_mode=1`,
      schttp: { apiLogSkip: true },
      signal: abortController?.signal,
      headers: { 'x-sph': '1' },
    })]
    if (this.paymentCode) {
      fetchList.push(schttp({
        url: `/api/checkout/get?paymentCode=${this.paymentCode}&prefetch=1&lite_mode=1`,
        schttp: { apiLogSkip: true },
        signal: abortController?.signal,
        headers: { 'x-sph': '1' },
      }))
    }
    return Promise.all(fetchList).then(res => {
      let result = { default: res[0] }
      if (this.paymentCode) {
        result[this.paymentCode] = res[1]
      }
      this._prefetchOnce()
      return result
    // eslint-disable-next-line no-console
    }).catch((e) => console.log('prefetch:' + e))
  },
  _setupTimer() {
    this.refreshTimer = setInterval(() => {
      this.refreshCache(this.paymentCode, true)
    }, this.refreshTime)
  },
  _unsetTimer() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }
  },
  _cancelTask() {
    this.cache = null
    this.abortController?.abort()
    this.abortController?.cancel()
    this._unsetTimer()
  },
  async _buildContext(cachePromise, fetchIndex) {
    const [liteInfo, cache] = await Promise.all([
      this.liteInfo || this._getLiteInfo(),
      cachePromise
    ])
    const { contextForSSR, redirectUrl } = cache?.[fetchIndex] || {}
    if (contextForSSR && !redirectUrl) {
      contextForSSR.language = liteInfo.language
      contextForSSR.paymentsLanguageData = liteInfo.paymentsLanguageData
      contextForSSR.locals.IMG_LINK = liteInfo.IMG_LINK
    }
    return cache?.[fetchIndex]
  },
  getCache(paymentCode = '') {
    let fetchIndex = paymentCode ? paymentCode : 'default'
    if (!this.active || this.stop || (paymentCode && this.paymentCode !== paymentCode)) {
      this.paymentCode = paymentCode
      return this._buildContext(this._request(true), fetchIndex)
    }

    if (this.cache) {
      this.abortController?.resolve()
      this.abortController = null
    }

    const result = this.cache || this._request(true)
    this._cancelTask()

    return this._buildContext(result, fetchIndex)
  },
  switchTaskState(status) {
    if (typeof window === 'undefined') return
    this.stop = !!status
    this.stop && this._cancelTask()
  },
  refreshCache(paymentCode = '', needAwaitResult = false) {
    if (!this.active || this.stop) return

    this.paymentCode = paymentCode
    if (needAwaitResult) {
      this._request().then(res => {
        this.cache = Promise.resolve(res)
        this._unsetTimer()
        this._setupTimer()
      })
    } else {
      this.cache = this._request()
      this._unsetTimer()
      this._setupTimer()
    }
  },
  flushJob() {
    this.protectAbortController?.abort()
  },
  onvisibilitychange() {
    const active = document.visibilityState !== 'hidden'
    if (!active) {
      this.leave = Date.now()
    } else if ((Date.now() - this.leave) > this.refreshTime) {
      this.cache = null
    }
    this.active = active
  }
})
const _prerenderCheckout = typeof window !== 'undefined'
  ? window._prerenderCheckout || (window._prerenderCheckout = prerenderCheckout())
  : prerenderCheckout()

export default _prerenderCheckout
