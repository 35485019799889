export default () => ({
  cartItemGuideAddOnState: {
    // 展示凑单提示的商品行 id
    currentGuideAddOnCartId: '',
    // 是否展示气泡
    showPopover: false,
    // 是否展示楼层提示
    showFloor: false,
    // 当前勾选的商品行id
    currentChangeItemId: '',
  }
})
