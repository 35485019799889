const mutations = {
  addWishCartIds({ filterState }, id) {
    if (!id) return
    filterState.wishCartIds.push(id)
  },
  removeWishCartIds({ filterState }, id) {
    if (!id) return
    filterState.wishCartIds = filterState.wishCartIds.filter((wishCartId) => wishCartId !== id)
  },
  clearWishCartIds({ filterState }) {
    filterState.wishCartIds = []
  },
}

export default mutations
