import { getCurrentInstance } from 'vue'

// let rootStore = null
let cartV2Store = null
let miniCartStore = null
export function useStore(type = 'cart', moduleName = '') {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  const module_name = vm.proxy.$store?.state?.mini_cart ? 'mini_cart' : vm.proxy.$store?.state?.cart_v2 ? 'cart_v2' : moduleName
  if(module_name == '') return console.error(`%c 🙅 ERROR: 未找到store模块名!`, 'background:red;color:white;font-size:12px;padding: 1px;')
  if(type == 'root') {
    // if(rootStore) return rootStore
    return vm.proxy.$store
  } else {
    if(module_name == 'mini_cart') {
      if(miniCartStore) return miniCartStore
    } else {
      if(cartV2Store) return cartV2Store
    }
    const handler = {
      get: function (obj, prop) {
        return prop in obj ? obj[prop] : null
      },
    }
    const getterHandler = {
      get: function (obj, prop) {
        if (prop.includes(`${module_name}/`)) {
          console.error(`%c 🙅 ERROR: 属性${prop} 不允许包含模块名前缀${module_name}/，请移除`, 'background:red;color:white;font-size:12px;padding: 1px;')
          return null
        }
        let _prop = `${module_name}/${prop}`
        return _prop in obj ? obj[_prop] : null
      },
    }
    
    let tempStore = {
      rootCartInfo: vm.proxy.$store.state.cartInfo || (typeof window !== 'undefined' ? window._gb_app_?.$store?.state?.cartInfo || null : null),
      state: new Proxy(vm.proxy.$store.state[module_name], handler),
      getters: new Proxy(vm.proxy.$store.getters, getterHandler),
      commit: (fnName, ...args) => {
        if (fnName.includes(`${module_name}/`)) {
          console.error(`%c 🙅 ERROR: commit方法${fnName} 不允许包含模块名前缀${module_name}/，请移除`, 'background:red;color:white;font-size:12px;padding: 1px;')
          return
        }
        let _fnName = `${module_name}/${fnName}`
        if(_fnName in vm.proxy.$store._mutations) {
          return vm.proxy.$store.commit(`${_fnName}`, ...args)
        } else if(fnName in vm.proxy.$store._mutations) {
          return vm.proxy.$store.commit(`${fnName}`, ...args)
        } else  if(window._gb_app_?.$store?._mutations && (fnName in window._gb_app_.$store._mutations)){
          return window._gb_app_.$store.commit(`${fnName}`, ...args)
        } else {
          return console.warn(`%c ⚠️ WARN: Mutation ${fnName} not found in store`, 'background:yellow;color:black;font-size:12px;padding: 1px;')
        }
      },
      dispatch: (fnName, ...args) => {
        if (fnName.includes(`${module_name}/`)) {
          console.error(`%c 🙅 ERROR: dispatch方法${fnName} 不允许包含模块名前缀${module_name}/，请移除`, 'background:red;color:white;font-size:12px;padding: 1px;')
          return
        }
        let _fnName = `${module_name}/${fnName}`
        if(_fnName in vm.proxy.$store._actions) {
          return vm.proxy.$store.dispatch(`${_fnName}`, ...args)
        } else if(fnName in vm.proxy.$store._actions) {
          return vm.proxy.$store.dispatch(`${fnName}`, ...args)
        } else  if(window._gb_app_?.$store?._actions && (fnName in window._gb_app_.$store._actions)){
          return window._gb_app_.$store.dispatch(`${fnName}`, ...args)
        } else {
          return console.warn(`%c ⚠️ WARN: Action ${fnName} not found in store`, 'background:yellow;color:black;font-size:12px;padding: 1px;')
        }
      },
    }
    if(module_name == 'mini_cart') {
      miniCartStore = tempStore
    } else {
      cartV2Store = tempStore
    }
    return tempStore
  }
}

export function clearStore(moduleName = 'cart_v2') {
  if(moduleName == 'mini_cart') {
    miniCartStore = null
  } else {
    cartV2Store = null
  }
}
