import { isTimestampWithinDuration } from './incentiveTools.js'
import { useLureBubbleHistory } from 'public/src/pages/product_app/footer/cart/hooks/useLureBubbleHistory.js'
import { useLastLureBubble } from 'public/src/pages/product_app/footer/cart/hooks/useLastLureBubble.js'

export default class CartBubble {
  constructor() {
    this.version = 'v1'
    this.mode = '' // OFF | different | same
    this.popTiming = '' // OFF | A | B
    this.config = {
      countdownDuration: 10, // 倒计时时长
      noActDuration: 3, // 无操作时长
      disappearDuration: 3, // 弹窗展示时长
      bubbleAutoDisappearDuration: 5, // popTiming为A的弹窗展示时长
      intervals: 3, // 切换间隔时长
      displayNum: 3, // 显示数量
      maxBubbleDurationSeconds: 3, // popTiming为B的弹窗最低展示时长
    }
    this.index = 0 // 气泡展示次数
    this.bubbleList = [] // 所有气泡
    this.validBubbleList = [] // 可展示的气泡
    this.displayedBubbleListMap = new Map() // 展示过的气泡
    this.blacklistCacheKeys = []

    this.lureBubbleHistory = useLureBubbleHistory()
    this.lastLureBubble = useLastLureBubble()
  }

  initialize(cartLureInfo, config) {
    this.mode = config?.mode || this.mode || 'OFF'
    this.popTiming = config?.popTiming || 'OFF'
    if (cartLureInfo?.config) this.config = cartLureInfo.config

    if (this.mode === 'different') this.displayedBubbleListMap.clear()
    this.index = 0
    this.blacklistCacheKeys = []

    this.updateBubbleList(cartLureInfo)

    if (this.validBubbleList.length === 0) return

    // 如果所有气泡都展示完毕, 清空已展示的气泡
    if (!this.validBubbleList.find(cartLure => !this.displayedBubbleListMap.get(cartLure.type))) {
      this.displayedBubbleListMap.clear()
    }
  }

  getLimitBubble() {
    return this.validBubbleList.find(f => f.bubble.timestamp && isTimestampWithinDuration(f.bubble.timestamp * 1000, this.config.countdownDuration * 60 * 1000))
  }

  getNextBubble() {
    const next = this.validBubbleList.find(bubble => !this.displayedBubbleListMap.get(bubble.type))
    return next
  }

  getIsMaxDisplayNum() {
    return this.index >= this.config.displayNum
  }

  addIndex() {
    this.index++
  }

  updateBubbleList(cartLureInfo) {
    this.version = cartLureInfo?.checkOutBtnLurePointBubble ? 'v2' : 'v1'
    if (this.version === 'v2') {
      this.bubbleList = cartLureInfo?.checkOutBtnLurePointBubble?.filter?.(cartLure => cartLure.lureType === 'normal') ?? []
    } else {
      this.bubbleList = cartLureInfo?.cartLureList?.filter?.(cartLure => cartLure.source === '1' || cartLure.source === '2') ?? []
    }
    this.updateValidBubbleList()
  }

  updateValidBubbleList() {
    if (!this.mode) return
    let res = []
    const lureShowHistory = this.lureBubbleHistory.getLureBubbleHistory()
    const lastLureBubble = this.lastLureBubble.getLastLureBubble()
    const lureShowed = []
    if (this.mode === 'same' && lastLureBubble) {
      // 需要联动，不走缓存
      this.displayedBubbleListMap.clear()
      res = this.rearrangeCartLureList([...this.bubbleList], lastLureBubble.type)
    } else {
      this.bubbleList.forEach(item => {
        if (this.blacklistCacheKeys.includes(item.cacheKey)) return
        if (this.mode === 'different') {
          if (lureShowHistory.find(f => f.type === item.uniqueType)) {
            lureShowed.push(item)
            return
          }
        }
        res.push(item)
      })
    }
    if (this.mode === 'different') {
      const lureShowedSort = []
      lureShowHistory.forEach(f => {
        const target = lureShowed.find(d => d.uniqueType === f.type)
        if (target) lureShowedSort.push(target)
      })
      const len = this.config.displayNum - res.length
      if (len > 0) {
        res.push(...lureShowedSort.slice(0, len))
      }
    }
    this.validBubbleList = res
  }

  setDisplayedBubbleListMap(bubble) {
    this.displayedBubbleListMap.set(bubble.type, bubble)
    this.lureBubbleHistory.setLureBubbleHistory('cartPage', bubble.uniqueType)
  }

  setBlacklistCacheKeys(cacheKeys) {
    this.blacklistCacheKeys.push(...cacheKeys)
    this.updateValidBubbleList()
  }

  rearrangeCartLureList(arr, type) {
    // 根据type找对应的对象索引
    const idIndex = arr.findIndex?.(item => item.uniqueType === type) ?? -1

    // 如果没有找到对应的type，返回原数组的拷贝
    if (idIndex === -1) {
      return [...arr]
    }

    // 创建新数组并将匹配type的对象放在最前面
    let newArr = [arr[idIndex]]

    // 如果匹配的对象是数组的最后一个元素，则直接将除了该对象外的其他元素放在其后
    if (idIndex === arr.length - 1) {
      newArr = newArr.concat(arr.slice(0, idIndex))
      return newArr
    }

    // 将匹配对象之后的元素添加到新数组
    newArr = newArr.concat(arr.slice(idIndex + 1))

    // 将匹配对象之前的元素添加到新数组尾部
    newArr = newArr.concat(arr.slice(0, idIndex))

    return newArr
  }

  getIntervals() {
    return this.popTiming == 'A' ? this.config.bubbleAutoDisappearDuration : this.config.intervals
  }
}
