export default () => ({
  filterState: {
    wishCartIds: [], // 标识在wishlist筛选项中要保留的购物车商品行
    selectFilterLabelIdByLocal: '', // 本地存储的筛选标签
    filterLabel: {}, // 中间层返回的标签信息
    allFilterLabels: [],  // 所有标签(平铺)
    selectFilterLabel: {}, // 选中的标签信息
    holdTopFilterTagIds: null, // 始终保持登顶的标签
    filterSlideShow: false, // 筛选收纳器
  }
})
