import { useLureBubbleHistory } from 'public/src/pages/product_app/footer/cart/hooks/useLureBubbleHistory.js'

export default class CartBubble {
  constructor(checkoutIncentive) {
    this.initialized = false
    this.checkoutIncentive = checkoutIncentive
    this.config = {
      countdownDuration: 10, // 倒计时时长
      noActDuration: 3, // 无操作时长
      disappearDuration: 3, // 弹窗展示时长
      intervals: 3, // 切换间隔时长
      displayNum: 3, // 显示数量
    }
    this.index = 0 // 气泡展示次数
    this.checkOutBtnLurePointBubbleBK = null
    this.bubbleList = [] // 所有气泡
    this.validBubbleList = [] // 可展示的气泡
    this.displayedBubbleListMap = new Map() // 展示过的气泡

    this.lureBubbleHistory = useLureBubbleHistory()
  }

  initialize(cartLureInfo) {
    this.initialized = true
    if (cartLureInfo?.realTimeConfig) this.config = cartLureInfo.realTimeConfig

    this.displayedBubbleListMap.clear()
    this.index = 0
    this.checkOutBtnLurePointBubbleBK = null
    this.bubbleList = []
    this.validBubbleList = []
    this.displayedBubbleListMap.clear()
  }

  getNextBubble() {
    const next = this.validBubbleList[0]
    return next
  }

  getHasNextBubble() {
    return !!this.validBubbleList[0]
  }

  getIsMaxDisplayNum() {
    return this.index >= this.config.displayNum
  }

  addIndex() {
    this.index++
  }

  updateBubbleList(cartLureInfo) {
    if (!this.initialized || cartLureInfo?._action !== 'modify') return
    if (this.checkOutBtnLurePointBubbleBK && this.checkOutBtnLurePointBubbleBK === cartLureInfo?.checkOutBtnLurePointBubble) return
    this.checkOutBtnLurePointBubbleBK = cartLureInfo?.checkOutBtnLurePointBubble
    this.bubbleList = cartLureInfo?.checkOutBtnLurePointBubble?.filter?.(bubble => bubble.lureType === 'realTime') ?? []
    this.updateValidBubbleList()

    // 实时类气泡有更新时，立即展示
    if (this.getHasNextBubble() && !this.getIsMaxDisplayNum()) {
      this.checkoutIncentive.triggerShowByRealTime()
    }
  }

  updateValidBubbleList() {
    this.validBubbleList = [...this.bubbleList]
  }

  filterValidBubbleList(bubble) {
    this.validBubbleList = this.validBubbleList.filter(f => f !== bubble)
  }

  setDisplayedBubbleListMap(bubble) {
    this.filterValidBubbleList(bubble)
    this.displayedBubbleListMap.set(bubble.type, bubble)
    this.lureBubbleHistory.setLureBubbleHistory('cartPage', bubble.uniqueType)
  }
}
