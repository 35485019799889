import schttp from 'public/src/services/schttp'
import { getLocalStorage } from '@shein/common-function'

const getAddressInfo = () => {
  const addressCookie = getLocalStorage('addressCookie')
  let addressInfo = {}
  if (addressCookie) {
    try {
      addressInfo = typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      console.log('获取地址缓存失败', error)
    }
  }
  return addressInfo
}

/**
 * 获取赠品列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @param {string} param.delivery_id 投放 id
 * @returns 
 */
export const getGiftProductList = ({ promotion_id, range, page, limit, delivery_id }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId || '')
  delivery_id && formData.append('delivery_id', delivery_id)

  return schttp({
    method: 'POST',
    url: '/promotion/gift_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
    },
    useBffApi: true
  })
}

/**
 * 获取加价购换购列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {string} param.brand 品牌code
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @param {string} param.delivery_id 投放 id
 * @param {string} param.filter_cate_id 过滤分类id
 * @param {string} param.cate_id 分类id
 * @param {string} param.did 商品分类投放id
 * @returns 
 */
export const getAddBuyProductList = ({ promotion_id, range, brand, page, limit, delivery_id, filter_cate_id, cate_id, did }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  brand && formData.append('brand', brand)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId || '')
  delivery_id && formData.append('delivery_id', delivery_id)
  filter_cate_id && formData.append('filter_cate_id', filter_cate_id)
  cate_id && formData.append('cate_id', cate_id)
  did && formData.append('did', did)

  return schttp({
    method: 'POST',
    url: '/promotion/add_buy_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 根据多语言key获取多语言
 * @param {object} data
 * @param {array<string>} data.languageKeys 多语言key
 * @example getLanguage({ languageKeys: ['SHEIN_KEY_PWA_17315', 'SHEIN_KEY_PWA_17316'] })
 * @returns 
 */
export const getLanguage = ({ languageKeys }) => {
  const { currency, appLanguage } = window.gbCommonInfo

  return schttp({
    method: 'POST',
    url: '/system/configs/multi_language',
    data: {
      languageKeys,
    },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
    },
    useBffApi: true
  })
}

/**
 * 获取购物车楼层数据
 * @returns {Promise<Object>}
 */
export const getCartEntranceGuideApi = async ({ country_id, auto_use_coupon }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/cart/get_entrance_guide',
    data: {
      country_id,
      auto_use_coupon
    },
    headers: {
      'Content-Type': 'application/json',
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
      'frontend-scene': 'page_web_common'
    },
    useBffApi: true
  })
}

const getSiteFreeMall = async (auto_use_coupon) => {
  let cache = null
  const addressCookie = getLocalStorage('addressCookie')
  if (addressCookie) {
    try {
      cache =  typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('getSiteFreeMall', error)
    }
  }
  const result = await getCartEntranceGuideApi({
    country_id: cache?.countryId,
    auto_use_coupon
  })
  if (result?.code == 0) {
    return result.info?.is_free_shipping == 1
  }
  return false
}

/**
 * 更新rootState
 */
export async function updateRootStateFreeShipping({ cartSumQuantity, auto_use_coupon }, callback) {
  let isFreeShipping = false
  // 有购物车数据时，才去请求免邮方式
  if (typeof window !== 'undefined' && cartSumQuantity) {
    isFreeShipping = await getSiteFreeMall(auto_use_coupon)
  }
  if (callback) {
    callback(isFreeShipping)
  } else {
    window?._gb_app_?.$store?.commit('changeFreeShippingState', { isFreeShipping })
    window?._gb_cart_tag_tips_?.update()
  }
}

/**
 * 获取分享链接
 * @param {object} param
 * @param {string} param.pageType 分享页面类型 1 商品四级页面
 * @param {string} param.shareUrl 分享链接
 * @returns 
 */
export const getMarketingGmtcShareLink = ({ pageType, shareUrl }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('pageType', pageType)
  formData.append('shareUrl', shareUrl)

  return schttp({
    method: 'POST',
    url: '/marketing/gmtc/shareLink',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 设置缓存
 * @param {object} data 
 * @param {string} data.scanType 缓存key
 * @param {string} data.cacheData 缓存值
 * @returns 
 */
export const setCache = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/common/busineseCache/update',
    data
  })
}
