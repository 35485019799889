import { withResolvers } from '../../utils/withResolvers'
import bottomPromoGuide from './bottomPromo/bottomPromoGuide.js'
import couponFilterGuide from './couponFilter/couponFilterGuide.js'
import newNavBarGuide from './newNavBar/newNavBarGuide.js'

class CartGuideManager {
  constructor() {
    this._isCompletedForAll = null
    this._resolve = null
    this.onceDisabled = false

    this.guides = []
    this.currentGuide = null
    this.isReoslved = false

    this.initialize()
    this.register()
  }


  initialize() {
    const { promise, resolve } = withResolvers()
    this._isCompletedForAll = promise
    this._resolve = resolve

    this.onceDisabled = false
  }

  register() {
    // 引导按数组顺序执行
    const guides = [
      newNavBarGuide, // 新导航栏引导
      bottomPromoGuide, // 底部活动融合引导
      couponFilterGuide, // 优惠券筛选引导
    ]
    this.guides = guides
  }

  setOnceDisabled(isReoslved = true) {
    this.onceDisabled = true
    this.isReoslved = isReoslved
  }

  // 是否所有引导都已初始化
  isInitializedForAll() {
    return Promise.all(this.guides.map(guide => guide.isInitialized()))
  }

  // 判断是否有引导正在显示
  async isCompletedForAll() {
    await this.isInitializedForAll()
    // 本次无需引导或者已经没有引导了，提前结束 promise
    if ((this.isReoslved && this.onceDisabled) || !this.getNextGuide()) this._resolve?.(true)
    return this._isCompletedForAll
  }

  // 开始引导
  async startGuide() {
    if (this.onceDisabled) {
      this.onceDisabled = false
      this.isReoslved && this._resolve?.(true)
      return
    }
    await this.isInitializedForAll()
    this.showNextGuide()
  }

  // 显示下一个引导
  showNextGuide() {
    const nextGuide = this.getNextGuide()
    if (nextGuide) {
      this.currentGuide = nextGuide
      try {
        this.currentGuide.start()
      } catch (e) {
        this.currentGuide.skip() // 出错时跳过引导
        console.log(e)
      }
    } else {
      this.currentGuide = null
      this._resolve?.(!this.guides.find(guide => guide.isEnd))
    }
  }

  getNextGuide() {
    const nextGuide = this.guides.find(guide => !(guide.isCompleted() || guide.isSkip))
    return nextGuide
  }
}

// 单例
const _CartGuideManager = typeof window !== 'undefined' ? window.__cartGuideManager__ || (window.__cartGuideManager__ = new CartGuideManager()) : {
  startGuide: () => {},
  initialize: () => {},
  setOnceDisabled: () => {},
  isCompletedForAll: () => Promise.resolve(true),
}

export default _CartGuideManager
