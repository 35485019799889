export const USE_BFF_API_KEYS = [
  'cart_v4', // 需要放在第一个位置
]

export const CART_CLICK_UN_PAID_ORDER_INFO = 'cart_click_unpaid_order_info'

export const POSKEYS = {
  posKeys: [
    'Aod',
    'CccEmptyCart', 
    'OpenCartCouponHelpernew',
    'CartCouponHelpernew',
    'CartGetCoupons',
    'TradeQuickship',
    'Discountlistshowlimitexceededtip',
    'Cartnoteligibleforcoupons',
    'Add',
  ],
  newPosKeys: [
    'componentswitch',
    'recmultiCard',
    'detailgoodsCard',
    'CartAddress',
    'BannerDistrictimage',
    'OrderPageCouponTest',
    'UnderPrice',
    'LowpriceUser',
    'ReturnCouponShow',
    'ShowPromotion',
    'Cartcouponadd',
    'CouponAddToCartToast',
    'recSwitch',
    'addJson',
    'addnewhot',
    'addnewhotJson',
    'cartLabels',
    'EstimatedDetailSwitch',
    'CouponHelperAddMultiple',
    'Cartshowcoupon',
    'Quickshiprecommendthreshold',
    'SheinFindSimilar',
    'Couponaddall',
    'brTaxhint',
    'ShopLineRetention',
    'UserBehaviorTips',
    'CartGoodsLabel',
    'TradeEventAtmosphere',
    'CartActivityColor',
    'TaxSwitch',
    'CartNotCheckedEstimate',
    'CartrowEstimatedCountdown',
    'DiscountInfoSwitch',
    'Discountlogo',
    'CartShare',
    'EstimatedPrice',
    'cartshippingaddpriority',
    'cartSelect',
    'orderCouponNewUser',
    'CartBottomCopy',
    'PayinCart',
    'CartQuickshipVisual',
    'IncentiveInform',
    'cartPromotionsPriority',
    'QSinformation',
    'CartShippingTopSwitch',
    'bottomNumberStyle',
    'evoluSHEINShow',
    'Cartstoreinrow',
    'CancelledorderTip',
    'cartDiscountStyle',
    'storeiconchange',
    'Cartrefund',
    'cartPromotions',
    'detailPriceCountDown',
    'LowestPriceDays',
    'paypalCartSwitch',
    'CartaddGoodsdetailSwitch',
    'defaultlocalsize',
    'cartRightsFuns',
    'CartNewCouponlist',
    'cartPriceStyle',
    'outofstocktips',
    'cartRecommendGuide',
    'promotionCouponMix',
    'CartOOSGoodsPosition',
    'PaymentSafetyExperiment',
    'PaymentSecurity',
    'cartRightsFreereturn',
    'cartGoodsAddPromos',
    'cartShowBrand',
    'cartStoreFreeGift',
    'cartRightsFreereturn',
    'cartRightsLabel',
    'promotionCouponMix',
    'ActivityOver',
    'CartRecommendTab',
    'CartrowWishlistIcon',
    'NewCartrowVision',
    'CartOrderSFSVisual',
    'CartAddTechnicalSwitch',
    'CartBehaviorPriority',
    'SameSellersInfo',
  ]
}
export const LANGUAGE_KEY = [
  // bottomPromoGuide
  'SHEIN_KEY_PWA_20715',
  'SHEIN_KEY_PWA_32757',

  // newGoodsRecommendTip
  'SHEIN_KEY_PWA_30500',
  'SHEIN_KEY_PWA_34564',

  // filter wishlist
  'SHEIN_KEY_PWA_36655',
  'SHEIN_KEY_PWA_36656',
  'SHEIN_KEY_PWA_36659',
  'SHEIN_KEY_PWA_36660',

  'SHEIN_KEY_PWA_14965',
  'SHEIN_KEY_PWA_17956',
  'SHEIN_KEY_PWA_15552',
  'SHEIN_KEY_PWA_15042',
  'SHEIN_KEY_PWA_15312',
  'SHEIN_KEY_PWA_15698',
  'SHEIN_KEY_PWA_16271',
  'SHEIN_KEY_PWA_15091',
  'SHEIN_KEY_PWA_20345',
  'SHEIN_KEY_PWA_14964',
  'SHEIN_KEY_PWA_15578',
  'SHEIN_KEY_PWA_31743',
  'SHEIN_KEY_PWA_17961',
  'SHEIN_KEY_PWA_16049',
  'SHEIN_KEY_PWA_17376',
  'SHEIN_KEY_PWA_17379',
  'SHEIN_KEY_PWA_17087',
  'SHEIN_KEY_PWA_15659',
  'SHEIN_KEY_PWA_30842',
  'SHEIN_KEY_PWA_30913',
  'SHEIN_KEY_PWA_18965',
  'SHEIN_KEY_PWA_17012',
  'SHEIN_KEY_PWA_15291',
  'SHEIN_KEY_PWA_17051',
  'SHEIN_KEY_PWA_20407',
  'SHEIN_KEY_PWA_20406',
  'SHEIN_KEY_PWA_15831',
  'SHEIN_KEY_PWA_15830',
  'SHEIN_KEY_PWA_31963',
  'SHEIN_KEY_PWA_26189',
  'SHEIN_KEY_PWA_26444',
  'SHEIN_KEY_PWA_16112',
  'SHEIN_KEY_PWA_31804',
  'SHEIN_KEY_PWA_33775',
  'SHEIN_KEY_PWA_33772',
  'SHEIN_KEY_PWA_16323',
  'SHEIN_KEY_PWA_15502',
  'SHEIN_KEY_PWA_20685',
  'SHEIN_KEY_PWA_17187',
  'SHEIN_KEY_PWA_24710',
  'SHEIN_KEY_PWA_17188',
  'SHEIN_KEY_PWA_17182',
  'SHEIN_KEY_PWA_17183',
  'SHEIN_KEY_PWA_18263',
  'SHEIN_KEY_PWA_17059',
  'SHEIN_KEY_PWA_17085',
  'SHEIN_KEY_PWA_17112',
  'SHEIN_KEY_PWA_15976',
  'SHEIN_KEY_PWA_17122',
  'SHEIN_KEY_PWA_17186',
  'SHEIN_KEY_PWA_17171',
  'SHEIN_KEY_PWA_31159',
  'SHEIN_KEY_PWA_15791',
  'SHEIN_KEY_PWA_15790',
  'SHEIN_KEY_PWA_17639',
  'SHEIN_KEY_PWA_18556',
  'SHEIN_KEY_PWA_15146',
  'SHEIN_KEY_PWA_15498',
  'SHEIN_KEY_PWA_15614',
  'SHEIN_KEY_PWA_15615',
  'SHEIN_KEY_PWA_17954',
  'SHEIN_KEY_PWA_15062',
  'SHEIN_KEY_PWA_30068',
  'SHEIN_KEY_PWA_15623',
  'SHEIN_KEY_PWA_31180',
  'SHEIN_KEY_PWA_30092',
  'SHEIN_KEY_PWA_26183',
  'SHEIN_KEY_PWA_23995',
  'SHEIN_KEY_PWA_30499',
  'SHEIN_KEY_PWA_22740',
  'SHEIN_KEY_PWA_24802',
  'SHEIN_KEY_PWA_31999',
  'SHEIN_KEY_PWA_32000',
  'SHEIN_KEY_PWA_32001',
  'SHEIN_KEY_PWA_32002',
  'SHEIN_KEY_PWA_32003',
  'SHEIN_KEY_PWA_32004',
  'SHEIN_KEY_PWA_32005',
  'SHEIN_KEY_PWA_32006',
  'SHEIN_KEY_PWA_32007',
  'SHEIN_KEY_PWA_24467',
  'SHEIN_KEY_PWA_24468',
  'SHEIN_KEY_PWA_24469',
  'SHEIN_KEY_PWA_32100',
  'SHEIN_KEY_PWA_32066',
  'SHEIN_KEY_PWA_32067',
  'SHEIN_KEY_PWA_29998',
  'SHEIN_KEY_PWA_31164',
  'SHEIN_KEY_PWA_20628',
  'SHEIN_KEY_PWA_26184',
  'SHEIN_KEY_PWA_24132',
  'SHEIN_KEY_PWA_23543',
  'SHEIN_KEY_PWA_23542',
  'SHEIN_KEY_PWA_20687',
  'SHEIN_KEY_PWA_16266',
  'SHEIN_KEY_PWA_20686',
  'SHEIN_KEY_PWA_20705',
  'SHEIN_KEY_PWA_15567',
  'SHEIN_KEY_PWA_16452',
  'SHEIN_KEY_PWA_15578',
  'SHEIN_KEY_PWA_16025',
  'SHEIN_KEY_PWA_16020',
  'SHEIN_KEY_PWA_15804',
  'SHEIN_KEY_PWA_15805',
  'SHEIN_KEY_PWA_15806',
  'SHEIN_KEY_PWA_15666',
  'SHEIN_KEY_PWA_15254',
  'SHEIN_KEY_PWA_15106',
  'SHEIN_KEY_PWA_16009',
  'SHEIN_KEY_PWA_16847',
  'SHEIN_KEY_PWA_14974',
  'SHEIN_KEY_PWA_15561',
  'SHEIN_KEY_PWA_15075',
  'SHEIN_KEY_PWA_15078',
  'SHEIN_KEY_PWA_16266',
  'SHEIN_KEY_PWA_24132',
  'SHEIN_KEY_PWA_15504',
  'SHEIN_KEY_PWA_16725',
  'SHEIN_KEY_PWA_16282',
  'SHEIN_KEY_PWA_17042',
  'SHEIN_KEY_PWA_17044',
  'SHEIN_KEY_PWA_15198',
  'SHEIN_KEY_PWA_16284',
  'SHEIN_KEY_PWA_23776',
  'SHEIN_KEY_PWA_15742',
  'SHEIN_KEY_PWA_31762',
  'SHEIN_KEY_PWA_17185',
  'SHEIN_KEY_PWA_32715',
  'SHEIN_KEY_PWA_32716',
  'SHEIN_KEY_PWA_32717',
  'SHEIN_KEY_PWA_32718',
  'SHEIN_KEY_PWA_32719',
  'SHEIN_KEY_PWA_30729',
  'SHEIN_KEY_PWA_32726',
  'SHEIN_KEY_PWA_32727',
  'SHEIN_KEY_PWA_23519',
  'SHEIN_KEY_PWA_23521',
  'SHEIN_KEY_PWA_23524',
  'SHEIN_KEY_PWA_23518',
  'SHEIN_KEY_PWA_32720',
  'SHEIN_KEY_PWA_32721',
  'SHEIN_KEY_PWA_32722',
  'SHEIN_KEY_PWA_32723',
  'SHEIN_KEY_PWA_32724',
  'SHEIN_KEY_PWA_32725',
  'SHEIN_KEY_PWA_27232',
  'SHEIN_KEY_PWA_24707',
  'SHEIN_KEY_PWA_20710',
  'SHEIN_KEY_PWA_14987',
  'SHEIN_KEY_PWA_23895',
  'SHEIN_KEY_PWA_18981',
  'SHEIN_KEY_PWA_18957',
  'SHEIN_KEY_PWA_17829',
  'SHEIN_KEY_PWA_32145',
  'SHEIN_KEY_PWA_31181',
  'SHEIN_KEY_PWA_20624',
  'SHEIN_KEY_PWA_30733',
  'SHEIN_KEY_PWA_32178',
  'SHEIN_KEY_PWA_32329',
  'SHEIN_KEY_PWA_32173',
  'SHEIN_KEY_PWA_32180',
  'SHEIN_KEY_PWA_31800',
  'SHEIN_KEY_PWA_32684',
  'SHEIN_KEY_PWA_32685',
  'SHEIN_KEY_PWA_32145',
  'SHEIN_KEY_PWA_16789',
  'SHEIN_KEY_PWA_33673',
  'SHEIN_KEY_PWA_14817',
  'SHEIN_KEY_PWA_26156',
  'SHEIN_KEY_PWA_15285',
  'SHEIN_KEY_PWA_31803',
  'SHEIN_KEY_PWA_31801',
  'SHEIN_KEY_PWA_20957',
  'SHEIN_KEY_PWA_20963',
  'SHEIN_KEY_PWA_17535',
  'SHEIN_KEY_PWA_23693',
  'SHEIN_KEY_PWA_22386',
  'SHEIN_KEY_PWA_23548',
  'SHEIN_KEY_PWA_23547',
  'SHEIN_KEY_PWA_23772',
  'SHEIN_KEY_PWA_24417',
  'SHEIN_KEY_PWA_23621',
  'SHEIN_KEY_PWA_23693',
  'SHEIN_KEY_PWA_30167',
  'SHEIN_KEY_PWA_15248',
  'SHEIN_KEY_PWA_20959',
  'SHEIN_KEY_PWA_20958',
  'SHEIN_KEY_PWA_25528',
  'SHEIN_KEY_PWA_20964',
  'SHEIN_KEY_PWA_21004',
  'SHEIN_KEY_PWA_25347',
  'SHEIN_KEY_PWA_15091',
  'SHEIN_KEY_PWA_25347',
  'SHEIN_KEY_PWA_15091',
  'SHEIN_KEY_PWA_18957',
  'SHEIN_KEY_PWA_17210',
  'SHEIN_KEY_PWA_25347',
  'SHEIN_KEY_PWA_15091',
  'SHEIN_KEY_PWA_27861',
  'SHEIN_KEY_PWA_20962',
  'SHEIN_KEY_PWA_26235',
  'SHEIN_KEY_PWA_17210',
  'SHEIN_KEY_PWA_18957',
  'SHEIN_KEY_PWA_25720',
  'SHEIN_KEY_PWA_29039',
  'SHEIN_KEY_PWA_29124',
  'SHEIN_KEY_PWA_16658',
  'SHEIN_KEY_PWA_25296',
  'SHEIN_KEY_PWA_17190',
  'SHEIN_KEY_PWA_17189',
  'SHEIN_KEY_PWA_21449',
  'SHEIN_KEY_PWA_21450',
  'SHEIN_KEY_PWA_24956',
  'SHEIN_KEY_PWA_25106',
  'SHEIN_KEY_PWA_26359',
  'SHEIN_KEY_PWA_20999',
  'SHEIN_KEY_PWA_25739',
  'SHEIN_KEY_PWA_15807',
  'SHEIN_KEY_PWA_29122',
  'SHEIN_KEY_PWA_22479',
  'SHEIN_KEY_PWA_24708',
  'SHEIN_KEY_PWA_28755',
  'SHEIN_KEY_PWA_28743',
  'SHEIN_KEY_PWA_24869',
  'SHEIN_KEY_PWA_24357',
  'SHEIN_KEY_PWA_25377',
  'SHEIN_KEY_PWA_15817',
  'SHEIN_KEY_PWA_24865',
  'SHEIN_KEY_PWA_18572',
  'SHEIN_KEY_PWA_28877',
  'SHEIN_KEY_PWA_15817',
  'SHEIN_KEY_PWA_24357',
  'SHEIN_KEY_PWA_25377',
  'SHEIN_KEY_PWA_28878',
  'SHEIN_KEY_PWA_27118',
  'SHEIN_KEY_PWA_28754',
  'SHEIN_KEY_PWA_28742',
  'SHEIN_KEY_PWA_15816',
  'SHEIN_KEY_PWA_18334',
  'SHEIN_KEY_PWA_22571',
  'SHEIN_KEY_PWA_22572',
  'SHEIN_KEY_PWA_15971',
  'SHEIN_KEY_PWA_15818',
  'SHEIN_KEY_PWA_31340',
  'SHEIN_KEY_PWA_29128',
  'SHEIN_KEY_PWA_22049',
  'SHEIN_KEY_PWA_22611',
  'SHEIN_KEY_PWA_17155',
  'SHEIN_KEY_PWA_18335',
  'SHEIN_KEY_PWA_17523',
  'SHEIN_KEY_PWA_28756',
  'SHEIN_KEY_PWA_28744',
  'SHEIN_KEY_PWA_28745',
  'SHEIN_KEY_PWA_24956',
  'SHEIN_KEY_PWA_20922',
  'SHEIN_KEY_PWA_23527',
  'SHEIN_KEY_PWA_23526',
  'SHEIN_KEY_PWA_23528',
  'SHEIN_KEY_PWA_20908',
  'SHEIN_KEY_PWA_20910',
  'SHEIN_KEY_PWA_20909',
  'SHEIN_KEY_PWA_21348',
  'SHEIN_KEY_PWA_20918',
  'SHEIN_KEY_PWA_20919',
  'SHEIN_KEY_PWA_21316',
  'SHEIN_KEY_PWA_21393',
  'SHEIN_KEY_PWA_26436',
  'SHEIN_KEY_PWA_26435',
  'SHEIN_KEY_PWA_26437',
  'SHEIN_KEY_PWA_26438',
  'SHEIN_KEY_PWA_26154',
  'SHEIN_KEY_PWA_26332',
  'SHEIN_KEY_PWA_26174',
  'SHEIN_KEY_PWA_26334',
  'SHEIN_KEY_PWA_20909',
  'SHEIN_KEY_PWA_21348',
  'SHEIN_KEY_PWA_26625',
  'SHEIN_KEY_PWA_26626',
  'SHEIN_KEY_PWA_23412',
  'SHEIN_KEY_PWA_26331',
  'SHEIN_KEY_PWA_23414',
  'SHEIN_KEY_PWA_26333',
  'SHEIN_KEY_PWA_23420',
  'SHEIN_KEY_PWA_23422',
  'SHEIN_KEY_PWA_26499',
  'SHEIN_KEY_PWA_26497',
  'SHEIN_KEY_PWA_26503',
  'SHEIN_KEY_PWA_26501',
  'SHEIN_KEY_PWA_26500',
  'SHEIN_KEY_PWA_26498',
  'SHEIN_KEY_PWA_26504',
  'SHEIN_KEY_PWA_26502',
  'SHEIN_KEY_PWA_28876',
  'SHEIN_KEY_PWA_28875',
  'SHEIN_KEY_PWA_28874',
  'SHEIN_KEY_PWA_28873',
  'SHEIN_KEY_PWA_23412',
  'SHEIN_KEY_PWA_23413',
  'SHEIN_KEY_PWA_23414',
  'SHEIN_KEY_PWA_23416',
  'SHEIN_KEY_PWA_23420',
  'SHEIN_KEY_PWA_23421',
  'SHEIN_KEY_PWA_23422',
  'SHEIN_KEY_PWA_23423',
  'SHEIN_KEY_PWA_25671',
  'SHEIN_KEY_PWA_25672',
  'SHEIN_KEY_PWA_25704',
  'SHEIN_KEY_PWA_25660',
  'SHEIN_KEY_PWA_25063',
  'SHEIN_KEY_PWA_15230',
  'SHEIN_KEY_PWA_31802',
  'SHEIN_KEY_PWA_26445',
  'SHEIN_KEY_PWA_33962',
  'SHEIN_KEY_PWA_33963',
  'SHEIN_KEY_PWA_34545',
  'SHEIN_KEY_PWA_31537',
  'SHEIN_KEY_PWA_34806',
  "SHEIN_KEY_PWA_35218",
  "SHEIN_KEY_PWA_32886",
  "SHEIN_KEY_PWA_35219",
  "SHEIN_KEY_PWA_35220",
  "SHEIN_KEY_PWA_35221",
  "SHEIN_KEY_PWA_35222",
  "SHEIN_KEY_PWA_35223",
  "SHEIN_KEY_PWA_35224",
  "SHEIN_KEY_PWA_35225",
  "SHEIN_KEY_PWA_35226",
  'SHEIN_KEY_PWA_22276',
  'SHEIN_KEY_PWA_23920',
  'SHEIN_KEY_PWA_34527',
  'SHEIN_KEY_PWA_35813',
  'SHEIN_KEY_PWA_35814',
  'SHEIN_KEY_PWA_35815',
  'SHEIN_KEY_PWA_27304',
  "SHEIN_KEY_PWA_37175",
  "SHEIN_KEY_PWA_37176",
]
