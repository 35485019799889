const action = {
  handleFilterLabel({ commit, state }, filterLabel) {
    let allFilterLabels = []
    let selectFilterLabel = { filterTagId: 'all' }
    for (let group of filterLabel?.expandFilterLabels ?? []) {
      const groupClassId =  group.groupClassId
      const titleTip = group.titleTip
      for (let label of group.items) {
        allFilterLabels.push({ ...label, groupClassId, titleTip })
        if (label?.isSelect == '1') {
          selectFilterLabel = { ...label, groupClassId, titleTip }
        }
      }
      // 只在首次获取数据时更新holdTopFilterTagIds
      if (groupClassId == 'coupon' && state.filterState?.holdTopFilterTagIds === null) {
        const holdTopFilterTagIds = group.items?.map(l => l.filterTagId)?.join(',') || ''
        commit('updateFilterState', { holdTopFilterTagIds })
      }
    }
    allFilterLabels.sort((a, b) => a.sortDoublePriority - b.sortDoublePriority)
    commit('updateFilterState', {
      filterLabel,
      allFilterLabels,
      selectFilterLabel,
      selectFilterLabelIdByLocal: '',
    })
  },
  // 清除筛选状态
  clearFilter({ state, dispatch, commit }) {
    commit('updateFilterState', { filterSlideShow: false })
    if (state.filterState?.selectFilterLabel?.filterTagId !== 'all') {
      commit('updateFilterState', {
        selectFilterLabelIdByLocal: '',
        selectFilterLabel: { 
          filterTagId: 'all',
        }
      })
      return dispatch('fetchCartIndex')
    }
  }
}


export default action
